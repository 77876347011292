html{
  font-size: 62.5%;
}
body {
  margin: 0;
  font-family: 'Inter';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

}

.app {
  text-align: center;
      width: 100%;
}

.app-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .app-logo {
    animation: app-logo-spin infinite 20s linear;
  }
}

.app-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.app-link {
  color: #61dafb;
}

@keyframes app-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
:root {
  --heading: 4.2rem;
  --heading2: 6.4rem;
  --sub-head: 2.4rem;
  --sub-head2: 2.2rem;
  --body1: 1.8rem;
  --body2: 1.6rem;
  --body3: 1.4rem;
  --inter: Inter;
  --thin: 400;
   --dark-black : #1F1F1F;
  --logo-dark : #0F464E;
  --dark-green: #066C2F;
  --green: #3DB76D;
  --gray: #6C6C6C;
  --light-green: #F6FFF9;
}
.container-custom{
  width: 90% ;
  margin: 0 auto ;
}